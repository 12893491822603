.publication-list {
  width: 600px;
  margin: 20px auto;
}

@media (max-width: 890px) {
  .publication-list {
    width: 100%;
  }

  .publication-card {
    width: 100%;
  }
}


.like-avatar{
  width: 20px !important;
  height: 20px !important;
}
